$baseline: 8px;
@import "https://use.typekit.net/rjj5vdz.css";
@import "_reset";
@import "_functions";

* {
  box-sizing: border-box;
}

body {
  color      : #000;
  font-family: proxima-nova, sans-serif;
  font-weight: 300;
  font-style : normal;
  font-size  : rs(2);
  line-height: 1;
}

.header {
  background: #191919;
  color     : #FFF;
  padding   : rs(8) rs(2) rs(2);
  text-align: center;
  position  : relative;

  &__smile {
    height: auto;
    width : 100vw;
  }
}

.body {
  padding   : rs(6) rs(2);
  text-align: center;
}

h1 {
  font-size  : rs(6);
  line-height: rs(6);
}

h2 {
  font-size    : rs(4);
  line-height  : rs(4);
  margin-bottom: rs(2);
}

.button {
  color          : #FFF;
  background     : #CE3527;
  border-radius  : rs(3);
  display        : inline-block;
  font-size      : rs(3);
  line-height    : rs(6);
  height         : rs(6);
  margin-bottom  : rs(4);
  padding        : 0 rs(3);
  text-decoration: none;
}